<template>
  <div>
		<!-- HANDELBAY INNOVATIVE -->
		<section id="handelbay-innovative">
			<div class="container">
				<div class="row">
					<div class="col-md-5 col-sm-6 col-md-offset-1 text-left">
						<div class="title" itemscope itemtype="https://handelbay.com/">
							<h2 class="title-1" itemprop="HandelBay Innvador">
								{{ $t('lang.why_handelbay.do_you_love_to_be_innovative?') }}
							</h2>
							<h3 class="title-2" itemprop="HandelBay en tu día a día">
								{{ $t('lang.why_handelbay.its_time_to_use') }}
								<br> 
								{{ $t('lang.why_handelbay.HandelBay_in_your_day_to_day') }}
							</h3>
							<span class="handelbay-active"></span>
						</div>
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<p itemprop="HandelBay es la solución">
								{{ $t('lang.why_handelbay.HandelBay_is_the_solution_that_you_have_been_waiting_for_so_long_for_the_shopping_area_and_the_commercial_area') }}
							</p>
							<p itemprop="HandelBay te permite optimizar">
								{{ $t('lang.why_handelbay.we_created_a_product_that_allows_you_to_optimize_your_day_to_day_executing_operational_tasks_more_efficiently_so_that_you_can_dedicate_yourself_to_actions_that_generate_value_for_your_company') }}
							</p>
						</div>
					</div>
					<div class="col-md-6 col-sm-6">
						<div class="computers">
							<img src="../assets/images/landing/handelbay/handelbay_computador.png" alt="Es hora de usar HandelBay en tu día a día">
						</div>
					</div>
				</div>
				<!-- .row -->
			</div>
			<!-- .container -->
		</section>
		<!-- END HANDELBAY INNOVATIVE -->

		<!-- .handelbay-background-curva -->
		<section id="handelbay-background-curva" class="top-backogrund-curva">
		</section>
		<!-- .handelbay-background-curva -->

		<!-- HANDELBAY ITEMS -->
		<section id="items-handelbay">
			<div class="">
				<div class="container">
					<div class="row">
						<div class="col-md-8 col-sm-12 col-md-offset-2">
							<div class="text-center tabs-handelbay-planes">
								<!-- Nav tabs -->
								<ul class="nav nav-tabs" role="tablist">
									<li role="presentation" class="active">
										<a href="#Abastecimiento" aria-controls="Abastecimiento" role="tab" data-toggle="tab" class="abaste" onclick="$('.context').show();">
										{{ $t('lang.why_handelbay.catering') }}
											<span></span>
										</a>
									</li>
									<li role="presentation">
										<a href="#Gestion_comercial" aria-controls="Gestion_comercial" role="tab" data-toggle="tab" class="gestion" onclick="$('.context').hide();">
											{{ $t('lang.why_handelbay.commercial_management_and_sales') }}
											<span></span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<!-- Tab panes -->
				<div class="tab-content">
					<div role="tabpanel" class="tab-pane active" id="Abastecimiento">
						<div class="container">
							<div class="row">
								<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
									<div class="title" itemscope itemtype="https://handelbay.com/">
										<h2 class="title-2" itemprop="Abastecimiento">
											{{ $t('lang.why_handelbay.catering') }}
										</h2>
										<span class="handelbay-active"></span>
									</div>
									<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
										<p itemprop="plataforma">
											{{ $t('lang.why_handelbay.by_using_HandelBay_as_your_business_shopping_platform') }}
										</p>
									</div>
									<div class="handelbay-tradicional-title text-center">
										<h4>
											{{ $t('lang.why_handelbay.HandelBay_vs_Traditional') }}
											<span class="line-left"></span>
											<span class="line-right"></span>
										</h4>
										<h5 style="vertical-align:middle;line-height: 20px;font-family:gotham_light;"> 
											{{ $t('lang.why_handelbay.below_you_will_find_a_comparison_in') }} 
											<b> 
												{{ $t('lang.why_handelbay.weather') }} 
											</b>, 
											{{ $t('lang.why_handelbay.between_processes') }} 
											<br>
											{{ $t('lang.why_handelbay.made_in_the_method') }} 
											<span style="font-weight: bold;font-family:Gotham-Bold;"> 
												{{ $t('lang.why_handelbay.traditional') }} 
											</span> 
											{{ $t('lang.why_handelbay.and_in') }} 
											<span style="color:#30e7a3; font-weight: bold;font-family:Gotham-Bold;"> 
												{{ $t('lang.why_handelbay.HandelBay') }} 
											</span>
										</h5>
									</div>
								</div>
							</div>
							<!-- .row -->
						</div>
						<!-- .container -->
						<div class="handelbay-tradicional-content">
							<div class="container">
								<div class="row">
									<div class="col-md-10 col-sm-12 col-md-offset-1 background-2 background-4">
										<div class="title-last">
											<span>
												{{ $t('lang.why_handelbay.search_of') }}
												<br>
												{{ $t('lang.why_handelbay.providers') }}
											</span>
										</div>
										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="cliente">
													{{ $t('lang.why_handelbay.search_of') }} {{ $t('lang.why_handelbay.providers') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 73px;"></span>
														</div>
														<span itemprop="Investigación">
															{{ $t('lang.why_handelbay.directories_internet') }}
														</span>
													</div>
												</div>
											</div>
										</div>
										<!-- .row -->

										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="mercados">
													{{ $t('lang.why_handelbay.supplier_information_and_documentation_request') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 84px;"></span>
														</div>
														<span itemprop="labor comercial">
															{{ $t('lang.why_handelbay.email') }}
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="row handelbay-progress">
												<div class="col-md-4 col-sm-4 first-text" itemscope>
													<p class="text-left-handelbay" itemprop="mercados">
														{{ $t('lang.why_handelbay.generate_vendor_recommendations') }}
													</p>
												</div>
												<div class="col-md-7 col-sm-7">
													<div class="text-right-handelbay">
														<div class="barra-progress" itemscope>
															<div>
																<span style="width: 84px;"></span>
															</div>
															<span itemprop="labor comercial">
																{{ $t('lang.why_handelbay.email') }}
															</span>
														</div>
													</div>
												</div>
										</div>

										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="mercados">
													{{ $t('lang.why_handelbay.classify_providers') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 84px;"></span>
														</div>
														<span itemprop="labor comercial">
															{{ $t('lang.why_handelbay.excel_or_similar') }}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- .row -->

								<div class="row">
									<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
										<div class="title-last">
											<span>
												{{ $t('lang.why_handelbay.purchasing_process') }}
											</span>
										</div>
										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="cliente">
													{{ $t('lang.why_handelbay.internal_communication_with_the_purchasing_area') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 100px;"></span>
														</div>
														<span itemprop="Investigación">
															{{ $t('lang.why_handelbay.ERP_/_email') }}
														</span>
													</div>
												</div>
											</div>
										</div>
										<!-- .row -->

										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="mercados">
													{{ $t('lang.why_handelbay.purchase_requisitions') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 84px"></span>
														</div>
														<span itemprop="labor comercial">
															{{ $t('lang.why_handelbay.ERP_/_email') }}
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="cliente">
													{{ $t('lang.why_handelbay.request_for_quotes') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 84px;"></span>
														</div>
														<span itemprop="Investigación">
															{{ $t('lang.why_handelbay.email_/_portal_of_each_company') }}
														</span>
													</div>
												</div>
											</div>
										</div>
										<!-- .row -->

										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="procesos de compra">
													{{ $t('lang.why_handelbay.publication_of_public_tenders') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 84px;"></span>
														</div>
														<span itemprop="portales">
															{{ $t('lang.why_handelbay.email_/_portal_of_each_company') }}
														</span>
													</div>
												</div>
											</div>
										</div>
										<!-- .row -->

										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="portafolio">
													{{ $t('lang.why_handelbay.send_and_receive_media_files') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 73px;"></span>
														</div>
														<span itemprop="visitas">
															{{ $t('lang.why_handelbay.email_/_portal_of_each_company') }}
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="row handelbay-progress">
											<div class="col-md-4 col-sm-4 first-text" itemscope>
												<p class="text-left-handelbay" itemprop="portafolio">
													{{ $t('lang.why_handelbay.receipt_of_quote') }}
												</p>
											</div>
											<div class="col-md-7 col-sm-7">
												<div class="text-right-handelbay">
													<div class="barra-progress" itemscope>
														<div>
															<span style="width: 73px;"></span>
														</div>
														<span itemprop="visitas">
															{{ $t('lang.why_handelbay.email_/_portal_of_each_company') }}
														</span>
													</div>
												</div>
											</div>
										</div>

										<div class="row handelbay-progress">
												<div class="col-md-4 col-sm-4 first-text" itemscope>
														<p class="text-left-handelbay" itemprop="portafolio">
															{{ $t('lang.why_handelbay.supplier_analysis_reputation_/_recommendations') }}
														</p>
												</div>
												<div class="col-md-7 col-sm-7">
														<div class="text-right-handelbay">
																<div class="barra-progress" itemscope>
																		<div>
																				<span style="width: 73px;"></span>
																		</div>
																		<span itemprop="visitas">
																			{{ $t('lang.why_handelbay.email_/_phone_/_referrals_/_third_parties') }}
																		</span>
																</div>
														</div>
												</div>
										</div>

										<div class="row handelbay-progress">
												<div class="col-md-4 col-sm-4 first-text" itemscope>
														<p class="text-left-handelbay" itemprop="portafolio">
															{{ $t('lang.why_handelbay.quote_comparison') }}
														</p>
												</div>
												<div class="col-md-7 col-sm-7">
														<div class="text-right-handelbay">
																<div class="barra-progress" itemscope>
																		<div>
																				<span style="width: 73px;"></span>
																		</div>
																		<span itemprop="visitas">
																			{{ $t('lang.why_handelbay.excel_or_similar') }}
																		</span>
																</div>
														</div>
												</div>
										</div>

										<div class="row handelbay-progress">
												<div class="col-md-4 col-sm-4 first-text" itemscope>
														<p class="text-left-handelbay" itemprop="portafolio">
															{{ $t('lang.why_handelbay.request_for_approvals_to_buy') }}
														</p>
												</div>
												<div class="col-md-7 col-sm-7">
														<div class="text-right-handelbay">
																<div class="barra-progress" itemscope>
																		<div>
																				<span style="width: 73px;"></span>
																		</div>
																		<span itemprop="visitas">
																			{{ $t('lang.why_handelbay.email') }}
																		</span>
																</div>
														</div>
												</div>
										</div>

										<div class="row handelbay-progress">
												<div class="col-md-4 col-sm-4 first-text" itemscope>
														<p class="text-left-handelbay" itemprop="portafolio">
															{{ $t('lang.why_handelbay.generation_of_purchase_orders') }}
														</p>
												</div>
												<div class="col-md-7 col-sm-7">
														<div class="text-right-handelbay">
																<div class="barra-progress" itemscope>
																		<div>
																				<span style="width: 73px;"></span>
																		</div>
																		<span itemprop="visitas">
																			{{ $t('lang.why_handelbay.ERP_/_text_editor_/_email') }}
																		</span>
																</div>
														</div>
												</div>
										</div>

										<div class="row handelbay-progress">
												<div class="col-md-4 col-sm-4 first-text" itemscope>
														<p class="text-left-handelbay" itemprop="portafolio">
															{{ $t('lang.why_handelbay.generation_of_purchase_orders') }}
														</p>
												</div>
												<div class="col-md-7 col-sm-7">
														<div class="text-right-handelbay">
																<div class="barra-progress" itemscope>
																		<div>
																				<span style="width: 73px;"></span>
																		</div>
																		<span itemprop="visitas">
																			{{ $t('lang.why_handelbay.ERP_/_text_editor_/_email') }}
																		</span>
																</div>
														</div>
												</div>
										</div>
										<!-- .row -->
									</div>
								</div>
								<!-- .row -->

								<div class="row">
										<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
												<div class="title-last">
														<span>
															{{ $t('lang.why_handelbay.process') }} 
															<br>
															{{ $t('lang.why_handelbay.post_purchase') }}
														</span>
												</div>
												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="cliente">
																	{{ $t('lang.why_handelbay.post_purchase_follow_up') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 78px;"></span>
																				</div>

																				<span itemprop="Investigación">
																					{{ $t('lang.why_handelbay.email_/_phone') }}
																				</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="mercados">
																	{{ $t('lang.why_handelbay.communication_with_suppliers_by_process') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 84px;"></span>
																				</div>

																				<span itemprop="labor comercial">
																					{{ $t('lang.why_handelbay.NA') }}
																				</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="procesos de compra">
																	{{ $t('lang.why_handelbay.informatio_traceability') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 100px;"></span>
																				</div>

																				<span itemprop="portales">
																					{{ $t('lang.why_handelbay.between_ERP_and_email_/_multiple_sources') }}
																				</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																	{{ $t('lang.why_handelbay.classification_and_evaluation_of_suppliers') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>

																				<span itemprop="visitas">
																					{{ $t('lang.why_handelbay.ERP_/_formats_of_each_company') }}
																				</span>
																		</div>
																</div>
														</div>
												</div>
										</div>
								</div>

								<div class="row">
										<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
												<div class="title-last">
														<span>
															{{ $t('lang.why_handelbay.optimization_of') }}
															<br>
															{{ $t('lang.why_handelbay.processes') }}
														</span>
												</div>
												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="cliente">
																	{{ $t('lang.why_handelbay.historical_purchase_prices') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 78px;"></span>
																				</div>

																				<span itemprop="Investigación">
																					{{ $t('lang.why_handelbay.ERP_/_email') }}
																				</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="mercados">
																	{{ $t('lang.why_handelbay.reports_generation') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 84px;"></span>
																				</div>

																				<span itemprop="labor comercial">
																					{{ $t('lang.why_handelbay.ERP_information_and_manual_process_/_not_done') }}
																				</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="procesos de compra">
																	{{ $t('lang.why_handelbay.replicate_purchase_process') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 100px;"></span>
																				</div>

																				<span itemprop="portales">- - </span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																	{{ $t('lang.why_handelbay.schedule_a_recurring_checkout') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>

																				<span itemprop="visitas">--</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																	{{ $t('lang.why_handelbay.transfer_of_purchase_processes') }}
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>

																				<span itemprop="visitas">
																					{{ $t('lang.why_handelbay.email_/_verbal') }}
																				</span>
																		</div>
																</div>
														</div>
												</div>
										</div>
								</div>
								<!-- .row -->
							</div>
						</div>
					</div>
					<div role="tabpanel" class="tab-pane" id="Gestion_comercial">
						<div class="container">
								<div class="row">
										<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
												<div class="title" itemscope itemtype="https://handelbay.com/">
														<h1 class="title-2" itemprop="Gestion_comercial">
															{{ $t('lang.why_handelbay.commercial_management') }}
														</h1>
														<span class="handelbay-active"></span>
												</div>
												<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
														<p itemprop="plataforma">
															{{ $t('lang.why_handelbay.can_you_imagine_a_CRM_that_is_filled_out_automatically?') }} 
															{{ $t('lang.why_handelbay.how_about_new_customers_looking_for_you_to_buy?') }} 
															{{ $t('lang.why_handelbay.how_do_you_tell_the_world_about_your_good_reputation_and_excellent_ratings_as_a_supplier?') }} 
															{{ $t('lang.why_handelbay.at_HandelBay_you_can_do_this') }} 
															{{ $t('lang.why_handelbay.plus_you_will_have_your_portfolio_online_24_7') }} 
															{{ $t('lang.why_handelbay.we_want_to_help_you_be_the_best_supplier') }} 
														</p>
												</div>
												<div class="handelbay-tradicional-title text-center">
														<h4>
															{{ $t('lang.why_handelbay.HandelBay_vs_Traditional') }}
															<span class="line-left"></span>
															<span class="line-right"></span>
														</h4>
												</div>
										</div>
								</div>
								<!-- .row -->
							</div>
							<!-- .container -->
							<div class="handelbay-tradicional-content">
									<div class="container">
											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
															<div class="title-last">
																	<span>
																		{{ $t('lang.why_handelbay.management') }}
																		<br>
																		{{ $t('lang.why_handelbay.commercial') }}
																	</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">
																				{{ $t('lang.why_handelbay.find_and_identify_a_new_customer') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 100px;"></span>
																							</div>
																							<span itemprop="Investigación">
																								{{ $t('lang.why_handelbay.market_research_/_internet') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="mercados">
																				{{ $t('lang.why_handelbay.reach_new_markets_and_publicize_the_company') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px"></span>
																							</div>
																							<span itemprop="labor comercial">
																								{{ $t('lang.why_handelbay.commercial_work') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																				{{ $t('lang.why_handelbay.search_for_new_purchasing_processes') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>
																							<span itemprop="portales">
																								{{ $t('lang.why_handelbay.multiple_portals_of_companies_and_associations') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																				{{ $t('lang.why_handelbay.present_the_complete_portfolio') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 89px;"></span>
																							</div>
																							<span itemprop="visitas">
																								{{ $t('lang.why_handelbay.website_/_commercial_visits') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="reputación">
																				{{ $t('lang.why_handelbay.publicize_the_reputation_of_the_company_and_name') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 100px;"></span>
																							</div>
																							<span itemprop="documentación">
																								{{ $t('lang.why_handelbay.sending_documentation_/_commercial_presentations_to_clients') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="Gestión">
																				{{ $t('lang.why_handelbay.customer_management') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 94px;"></span>
																							</div>
																							<span itemprop="CRM">
																								{{ $t('lang.why_handelbay.CRM_/_excel_or_similar') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->

											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-2">
															<div class="title-last">
																	<span>
																		{{ $t('lang.why_handelbay.process') }}
																		<br>
																		{{ $t('lang.why_handelbay.selling') }}
																	</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">
																				{{ $t('lang.why_handelbay.reception_of_invitations_to_participate_in_processes') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 73px;"></span>
																							</div>
																							<span itemprop="Investigación">
																								{{ $t('lang.why_handelbay.multiple_company_portals_and_associations') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="mercados">
																				{{ $t('lang.why_handelbay.successful_invitations_according_to_the_companys_portfolio') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="labor comercial">
																								{{ $t('lang.why_handelbay.NA') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																				{{ $t('lang.why_handelbay.business_reference_request') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 94px;"></span>
																							</div>
																							<span itemprop="portales">
																								{{ $t('lang.why_handelbay.email') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																				{{ $t('lang.why_handelbay.participation_in_tenders_and/or_processes_of_greater_amount') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="visitas">
																								{{ $t('lang.why_handelbay.multiple_portals_of_companies_and_associations') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="reputación">
																				{{ $t('lang.why_handelbay.pending_quote_reminders') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 94px;"></span>
																							</div>
																							<span itemprop="documentación">
																								{{ $t('lang.why_handelbay.NA') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="Gestión">
																				{{ $t('lang.why_handelbay.quote') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>
																							<span itemprop="CRM">
																								{{ $t('lang.why_handelbay.email') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="Gestión">
																				{{ $t('lang.why_handelbay.sending_commercial_information_and_company_documentation') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="CRM">
																								{{ $t('lang.why_handelbay.email') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->

											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-1 background-3">
															<div class="title-last">
																	<span>
																		{{ $t('lang.why_handelbay.process') }}
																		<br>
																		{{ $t('lang.why_handelbay.aftermarket') }}
																	</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">}
																				{{ $t('lang.why_handelbay.individual_communication_with_clients_by_process') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="Investigación">
																								{{ $t('lang.why_handelbay.NA') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																				{{ $t('lang.why_handelbay.after_sales_follow_up') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="portales">
																								{{ $t('lang.why_handelbay.email') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																				{{ $t('lang.why_handelbay.receipt_of_grades') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 73px;"></span>
																							</div>
																							<span itemprop="visitas">
																								{{ $t('lang.why_handelbay.NA_/_survey') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->

											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-2 background-4">
															<div class="title-last">
																	<span>
																		{{ $t('lang.why_handelbay.optimization') }}
																		<br>
																		{{ $t('lang.why_handelbay.of_process') }}
																		<br>
																		{{ $t('lang.why_handelbay.selling') }}
																	</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">
																				{{ $t('lang.why_handelbay.commercial_sales_management') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>

																							<span itemprop="Investigación">
																								{{ $t('lang.why_handelbay.CRM_/_excel_or_similar') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="mercados">
																				{{ $t('lang.why_handelbay.generation_of_commercial_sales_process_reports') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>

																							<span itemprop="labor comercial">
																								{{ $t('lang.why_handelbay.ERP') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																				{{ $t('lang.why_handelbay.account_allocation_model') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 100px;"></span>
																							</div>

																							<span itemprop="portales">
																								{{ $t('lang.why_handelbay.CRM_/_excel_or_similar') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																				{{ $t('lang.why_handelbay.transfer_of_responsibility_for_sales_processes') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 73px;"></span>
																							</div>

																							<span itemprop="visitas">
																								{{ $t('lang.why_handelbay.email') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="reputación">
																				{{ $t('lang.why_handelbay.traceability_of_sales_processes') }}
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>

																							<span itemprop="documentación">
																								{{ $t('lang.why_handelbay.CRM_/_excel_or_similar') }}
																							</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->
									</div>
							</div>
					</div>
				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-8 col-sm-12 col-md-offset-2">
							<div class="text-center tabs-handelbay-planes">
								<!-- Nav tabs -->
								<ul class="nav nav-tabs" role="tablist">
									<li role="presentation" class="active">
										<a href="#Abastecimiento" aria-controls="Abastecimiento" role="tab" data-toggle="tab" class="abaste" onclick="$('.context').show();">
											{{ $t('lang.why_handelbay.catering') }}
											<span></span>
										</a>
									</li>
									<li role="presentation">
										<a href="#Gestion_comercial" aria-controls="Gestion_comercial" role="tab" data-toggle="tab" class="gestion" onclick="$('.context').hide();">
											{{ $t('lang.why_handelbay.commercial_management_and_sales') }}
											<span></span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- END HANDELBAY ITEMS -->

		<!-- .handelbay-background-curva -->
		<section id="handelbay-background-curva-bottom">
		</section>
		<!-- .handelbay-background-curva -->

		<!-- TRADITIONAL HANDELBAY -->
		<section id="traditional-handelbay">
			<div class="container">
				<div class="row">
					<div class="col-md-10 col-sm-12 col-md-offset-1">
						<div class="handelbay-text text-center context" itemscope itemtype="https://handelbay.com/">
							<p itemprop="plataforma">
								{{ $t('lang.why_handelbay.at_HandelBay_it_is_possible_to_carry_out_traceability_and_monitoring_of_everything_associated_with_the_process') }}
								<br>
								{{ $t('lang.why_handelbay.supply') }} 
								{{ $t('lang.why_handelbay.generating_value_to_the_purchasing_area_when_making_decisions_and_carrying_out_operational_work') }} 
								{{ $t('lang.why_handelbay.even_reducing_by_70%_the_time_invested_in_carrying_out_a_purchase_process') }}
							</p>
						</div>
						<div class="handelbay-tradicional-title text-center context">
							<h4>
								{{ $t('lang.why_handelbay.HandelBay_|_Traditional') }}
								<span class="line-left"></span>
								<span class="line-right"></span>
							</h4>
						</div>
						<img src="../assets/images/landing/handelbay/handelbay_tradicional_full.png" class="handelbay_img_tradicional context" alt="">
						<div class="title-footer text-center">
							<h2 class="title">
								{{ $t('lang.why_handelbay.do_you_want_to_buy_or_sell_on_HandelBay?') }} 
								</h2>
							<a href="#" class="btn btn-handelbay-success" data-toggle="modal" data-target="#modal-demo">
								{{ $t('lang.why_handelbay.schedule_your_demo') }} 
							</a>
							<a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
								{{ $t('lang.why_handelbay.sign_up_free') }} 
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- END TRADITIONAL HANDELBAY -->
		<PorqueHandelbay />
		<ModalTuDemo />

  </div>
</template>
<script>
import global from "@/components/Global.vue"
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"
export default {
	components:{
		PorqueHandelbay,
		ModalTuDemo
	},
	data(){
		return{
			global: global
		}
	}
}
</script>